import React, { Component } from 'react'

import UserPermissions from '../../components/users/permissions'

class Permissions extends Component {
  constructor (props) {
    super(props)
    this.state = { user: undefined }
  }

  render () {
    const { user } = this.props

    if (!user) return null

    const { permissions } = user
    return <UserPermissions permissions={permissions} />
  }
}

export default Permissions
